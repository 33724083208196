import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import '~/styles/main.scss';
import OwnersManualPage from '.';

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.OwnersManualPage = OwnersManualPage;
