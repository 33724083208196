import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { OwnersManualViewModel } from './models/owners-manual-model';
import { SvgIcon, TooltipDisclaimer } from '~/common/components/ui-elements';

const OwnersManualPage = (props: OwnersManualViewModel) => {
  const { manualsData, textDescription } = props;

  return (
    <div id="owners-manual" className={styles.containerRow}>
      <div className={styles.contentHeader}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: props.headerTitle as string }}
        />
        {props.tooltipDisclaimer && (
          <TooltipDisclaimer disclaimer={props.tooltipDisclaimer} />
        )}
      </div>
      <div className={styles.vehicleName}>{manualsData?.description}</div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: textDescription as string,
        }}
      />
      <div className={styles.rowBrochure}>
        {props.brochures.map((item, index) => (
          <div key={index} className={styles.brochure}>
            <a
              className={styles.btnImage}
              href={item.url}
              target="_blank"
              rel="noreferrer"
            >
              <SvgIcon size={2} type="pdfIcon" />
              <span>{item.title}</span>
            </a>
          </div>
        ))}
      </div>
      <div className={styles.rowCta}>
        {props.ctaImages.map((item, index) => (
          <a
            key={index}
            className={styles.ctaImage}
            href={item.url}
            target={item.target ?? '_self'}
            rel="noreferrer"
            data-text={item.buttonText}
          >
            <img src={item.image} alt={item.buttonText} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default OwnersManualPage;
